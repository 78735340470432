<!-- 出库单记录 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <FormItem class="">
        <span class="">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <!-- 出库单号 -->
      <FormItem class="marginLeft40">
        <span>盘亏单号：</span>
        <Input placeholder="请选择" v-model="queryFrom.win_order_number" clearable class="iviewIptWidth250"></Input>
      </FormItem>
      <!-- 供应商名称 -->
      <FormItem class="marginLeft40">
        <span>客户名称：</span>
        <Select placeholder="请选择" v-model="customer_index" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in customIdList" :value="index" :key="index">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <!-- 盘盈时间 -->
      <FormItem class="">
        <span>出库时间：</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" format="yyyy-MM-dd" clearable @on-change="changeTime($event, 1)"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" format="yyyy-MM-dd" v-model="queryFrom.create_time_end" clearable :options="options" @on-change="changeTime($event, 2)"></DatePicker>
      </FormItem>
      <!-- 出库仓库 -->
      <FormItem>
        <span>出库仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 经手人 -->
      <FormItem class="marginLeft40">
        <span>经手人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in userList" :value="item.id" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!--按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="searchItem">查询</span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv">
      <Spin fix v-if="loading"></Spin>
      <Table :columns="listColumns" :data="listData" highlight-row border></Table>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !chaxunFlag">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="chaxunFlag">
        <img style="margin-top: 100px;" src="../../assets/images/erpNoData.png" />
        <p class="noDataImg">请选择查询条件进行查询</p>
      </div>
    </div>
  </div>
</template>

<script>
// import CustomTable from '@/components/table'
import http from '@/service/baseUrl'
import FormUploadFile from '@/components/formUploadFile.vue'
import { debounce } from '@/service/utils'
import { mapState } from 'vuex'
export default {
  name: 'warehouseEntryRecord',
  components: {
    // CustomTable,
    FormUploadFile,
  },
  // 计算金额数量
  computed: {
    //  总数量
    TotalQuantity() {
      let total = 0
      this.analyticalData.forEach(item => {
        total = total + item.quantity
      })
      return total
    },
    // 总金额
    TotalMoney() {
      let total = 0
      this.analyticalData.forEach(item => {
        total = total + item.unit_price * item.quantity
      })
      return total.toFixed(2)
    },
  },
  data() {
    return {
      chaxunFlag: true,
      loading: false,
      customer_index: -1,
      customIdList: [],
      billNumber: '',
      Loading: false,
      paging: null,
      template_id: '',
      template_type: 3,
      typeList: [],
      status: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
        },
        {
          title: '盘亏单号',
          key: 'loss_order_number',
          align: 'center',
          minWidth: 180,
          maxWidth: 300,
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '出库仓库',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '出库时间',
          key: 'create_time',
          align: 'center',
          minWidth: 120,
          maxWidth: 300,
        },
        {
          title: '经手人',
          key: 'create_user',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            let str = this.$utils.formatMoney(param.row.total_amount, true)
            return h(
              'div',
              {},
              // param.row.total_amount
              str
            )
          },
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 180,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      clickId: null,
      // 反冲确认弹窗 + 出库单id
      isConfirm: [false, undefined],
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      // 查询参数
      queryFrom: {
        product_code: '',
        win_order_number: '',
        hospital_id: '', // 客户id
        warehouse_id: '', // 仓库id
        create_time_start: '', // 起始时间
        create_time_end: '', // 截止时间
        create_by: '', // 经手人
      },
      // 搜索条件
      searchObj: {},
      // 供应商下拉
      supplierSelect: [],
      // 经手人列表
      userList: [],
      // 仓库下拉
      warehouseList: [],
      // 导入确认弹窗
      analytical: false,
      // 待确认头部数据
      analyticalTopData: [],
      // 待确认表格数据
      analyticalData: [],
      trime: null,
      productList: [],
      modelList: [],
      // 时间设置
      options: {},
    }
  },
  computed: {
    ...mapState(['supplierNameList', 'clientNameList']),
  },
  methods: {
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsInventoryLoss',
        query: {
          loss_order_number: row.loss_order_number,
        },
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.create_time_start = e
        that.queryFrom.create_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start)
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
    /* 获取表格数据 */
    getTableList(pages, searchItem) {
      this.chaxunFlag = false
      this.loading = true
      let obj = {}
      Object.assign(obj, pages, searchItem)
      this.$http.get(this.$api.lossOrderManage, obj, false).then(res => {
        this.loading = false
        if (res.status) {
          this.listData = res.data
          this.total = res.total

          for (const item of this.listData) {
            item.create_time = item.create_time ? this.$moment.unix(item.create_time).format('YYYY-MM-DD') : null
          }
        }
      })
    },
    /* 分页方法 */
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchObj)
    },
    /* 搜索产品 */
    searchItem() {
      let obj = {}
      // 获取客户信息
      if (this.customer_index > -1) {
        obj.customer_id = this.clientNameList[this.customer_index].customer_id
        obj.customer_type = this.clientNameList[this.customer_index].customer_type
      } else {
        obj.customer_id = ''
        obj.customer_type = ''
      }
      obj.win_order_number = this.queryFrom.win_order_number
      obj.product_code = this.queryFrom.product_code
      obj.warehouse_id = this.queryFrom.warehouse_id
      obj.create_time_start = this.queryFrom.create_time_start
      obj.create_time_end = this.queryFrom.create_time_end
      obj.create_by = this.queryFrom.create_by
      this.searchObj = obj
      this.pages = { page: 1, rows: 10 }
      this.getTableList(this.pages, obj)
    },
    /* 获取下拉列表 */
    getSelect() {
      // 获取客户下拉
      this.$http.get(this.$api.warehousePickingListCustomer, false).then(res => {
        this.customIdList = res.data
      })
      // 仓库下拉
      let data = {
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWareHouseList, data, false).then(res => {
        this.warehouseList = res.data
      })
      // 经手人下拉
      this.$http.get(this.$api.publicSpecialUser, { user_type: '1' }, false).then(res => {
        this.userList = res.data
      })
    },
  },
  created() {
    // this.getTableList(this.pages, {})
    this.getSelect()
  },
}
</script>

<style scoped lang="less">
// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}

// 反冲确认弹窗
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}

// 导入确认弹窗
.modal {
  .money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      padding-right: 8px;
    }
  }

  .foot {
    text-align: right;
    margin-top: 20px;
  }
}

.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.totalNumber {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0px;
  padding: 0px 30px;
  box-sizing: border-box;
  .item {
    margin: 0px 15px;
    span {
      // color
    }
  }
}
.marginLeft20 {
  margin-left: 20px;
}
.iviewIptWidth200 {
  width: 200px;
}
.noDataImg {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-54%) translateY(-50%);
  font-size: 22px;
  color: #ccc;
}
</style>
